<template>
  <div class="page">
    <PageHeader
      :back="false"
      :icon="$t('ACCOUNT.ICON')"
      :title="$t('ACCOUNT.TITLE')"
    >
      <v-tooltip bottom transition="none">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            depressed
            color="#CFD8DC"
            class="ml-2"
            v-bind="attrs"
            v-on="on"
            @click="showEdit = true"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        {{ $t('COMMON.EDIT') }}
      </v-tooltip>
    </PageHeader>

    <Side
      component="forms/account/compact/FormAccountCompact"
      :value="showEdit"
      :ids="user.ids"
      @close="closeEdit"
      @update="updatePage"
    />

    <Loading :value="loading" />
    <v-row class="height-30 mt-10 justify-center">
      <v-col sm="11" md="5">
        <v-card flat class="pa-10 my-5">
          <v-row class="justify-center align-center">
            <v-avatar
              class="ma-3"
              size="125"
              tile
            >
              <v-img
                max-width="250"
                class="rounded_img"
                :src="$getImgSrc(user.images, 'lg', '/img/empty-photo.png')"
              />
            </v-avatar>
            <div class="ml-5 mr-5">
              <v-row no-gutters class="justify-space-between align-center">
                <h2>{{ user.firstName }} {{ user.lastName }}</h2>
                <v-chip label color="accent" class="ml-5">{{ user.role }}</v-chip>
              </v-row>
              <p>{{user.email}}</p>
              <p v-if="user.phone_number">{{ user.phone_number }}</p>
              <p>
                {{ user.country }}
              </p>
              <p class="grey--text">
                {{ $t('COMMON.CREATED') }} {{ $moment(user.created_at).fromNow() }}
                - {{ $t('INPUT.UPDATED') }} {{ $moment(user.updated_at).fromNow() }}
              </p>
            </div>
          </v-row>
        </v-card>
        <v-hover v-slot="{ hover }">
          <v-card flat class="transition-fast-in-fast-out pa-10 my-5 cursor-pointer" @click="goToCompany">
            <v-row class="justify-center align-center">
              <v-avatar
                class="ma-3"
                size="125"
                tile
              >
                <v-img
                  max-width="250"
                  class="rounded_img"
                  :src="$getImgSrc(company.images, 'lg', '/img/empty-image.png' )"
                />
              </v-avatar>
              <div class="ml-5 mr-5">
                <h2>{{ company.title }}</h2>
                <p> {{listOfUsers}} personnes </p>
                <p> {{company.country}} </p>
                <p class="grey--text">
                  {{ $t('COMMON.CREATED') }} {{ $moment(company.created_at).fromNow() }}
                  - {{ $t('INPUT.UPDATED') }} {{ $moment(company.updated_at).fromNow() }}
                </p>
              </div>
              <v-fade-transition>
                <v-icon size="42" style='position: absolute; right: 15px'  v-if="hover">
                  mdi-chevron-right
                </v-icon>
              </v-fade-transition>
            </v-row>
          </v-card>
        </v-hover>

      </v-col>
      <v-col sm="11" md="6">
        <v-row no-gutters>
          <v-col sm="12" md="12" lg="6">
            <v-card flat class="px-5 pt-5 my-5 mr-5">
              <v-card-title class="font-weight-bold">
                {{$t('COMMON.LIST_PRODUCTS')}}
              </v-card-title>
              <v-card-text class="pt-10">
                <template v-for="(product, i) in user.products">
                  <div style="background-color: #f0f0f0; border-radius: 10px" class="my-5" :key="`product-${i}`">
                    <v-row no-gutters class="justify-space-between align-center pl-5">
                      <h3 class="primary--text">{{product.name}}</h3>
                      <v-switch color="accent" inset readonly v-model="product.access" />
                    </v-row> 
                  </div>
                </template>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col sm="12" md="12" lg="6">
            <v-card flat class="pt-5 px-5 my-5">
              <v-card-title class="font-weight-bold">
                {{$t('COMMON.LIST_SESSIONS')}}
              </v-card-title>
              <v-card-text>

                <v-timeline
                  align-left
                  dense
                >
                  <template v-for="(session, i) in user.sessions">
                    <v-timeline-item
                      color="grey"
                      small
                      :key="`session-${i}`"
                    >
                      <v-row>
                        <v-col>
                          <strong>
                            {{new Date(session).toLocaleDateString('fr-FR')}}</strong>
                          <div class="text-caption">
                            {{new Date(session).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })}}
                          </div>
                        </v-col>
                      </v-row>
                    </v-timeline-item>
                  </template>
                </v-timeline>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader"
import Loading from "@/components/Loading"
import Side from '@/components/Side'
import { createLogger } from 'vuex';

export default {
  name: "PageAccount",
  components: {
    PageHeader,
    Loading,
    Side,
  },
  data: () => ({
    showEdit: false,
    valid: false,
    loading: false,
    selected: 0,
    company: {
      title: undefined,
      images: undefined,
      created_at: undefined,
      updated_at: undefined,
      country: undefined,
    },
    listOfUsers: undefined,
    user: {
      ids: [],
      images: undefined,
      firstName: undefined,
      lastName: undefined,
      phone_number: undefined,
      country: undefined,
      email: undefined,
      company: undefined,
      office: undefined,
      created_at: undefined,
      updated_at: undefined,
      isAdmin: false,
      sessions: [
        "1925-10-18 04:06:23",
        "1962-12-19 23:41:14",
        "2009-10-08 15:08:37",
        "2049-12-23 13:25:10",
        "1946-06-25 14:39:09",
      ],
      products: [
        {name: "XMultiDiff", access: true},
        {name: "XTrading", access: true},
        {name: "XRanking", access: false},
      ]
    },
    uploadImage: null,
    uploadImageUrl: null,
  }),
  async created () {
    try {
      this.loading = true
      this.loadUser()
    } finally {
      this.loading = false
    }
  },
  methods: {

    async loadUser () {
      const response = await this.$services.api_user.user_session.check()
      this.user.ids.push(response.data.user.id)
      this.user.country = response.data.user.country.title
      this.user.role = response.data.user.role.title
      this.user.company = response.data.user.company.title
      this.user.office = response.data.user.office.title

      const user = await this.$services.api_user.user.search({where: { id: response.data.user.id }})
      this.user.images = user.data[0].images
      this.user.firstName = user.data[0].firstname
      this.user.lastName = user.data[0].lastname
      this.user.phone_number = user.data[0].phone_number
      this.user.email = user.data[0].email
      this.user.created_at = user.data[0].created_at
      this.user.updated_at = user.data[0].updated_at
      this.user.isAdmin = user.data[0].admin

      const company = await this.$services.api_user.company.search({where: {id: response.data.user.company_id}})
      this.company.title = company.data[0].title
      this.company.images = company.data[0].images
      this.company.created_at = company.data[0].created_at
      this.company.updated_at = company.data[0].updated_at
      this.company.country = company.data[0].country.title

      const usersOfCompany = await this.$services.api_user.user.search({where: {company_id: response.data.user.company_id}})
      this.listOfUsers = usersOfCompany.data.length

      const userSessions = await this.$services.api_user.user_session.search({where: {}})
    },

    goToCompany () {
      this.$router.push('admin/company');
    },

    getImageUrl () {
      this.uploadImageUrl = this.uploadImage
        ? URL.createObjectURL(this.uploadImage)
        : this.user.image
          ? this.user.image
          : "/img/empty-photo.png";
    },

    closeEdit () {
      this.showEdit = false
    },

    updatePage () {
      this.$store.dispatch('app/init')
    }
  },
};
</script>

<style scoped>
p {
  margin: 0;
}
.page {
  overflow: auto;
}

.rounded_img {
  border-radius: 50%;
}

</style>
